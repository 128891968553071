import React, { useEffect, useState } from "react"
import UnderConstructionIllustration from "../app/components/illustrations/UnderConstructionIllustration"
import { Box, Stack, Typography } from "@mui/material"

const MAINTENANCE_START = process.env.NEXT_PUBLIC_MAINTENANCE_START_TIME
const MAINTENANCE_END = process.env.NEXT_PUBLIC_MAINTENANCE_END_TIME

const MaintenanceErrorTimer = () => {
  const startTime = new Date(MAINTENANCE_START).getTime()
  const endTime = new Date(MAINTENANCE_END).getTime()

  const initialTimeLeft = endTime - startTime
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft)

  useEffect(() => {
    const interval = setInterval(() => {
      const currentUTCTime = new Date(new Date().toISOString()).getTime();
      if (startTime <= currentUTCTime && currentUTCTime <= endTime && startTime<=endTime) {
        const remaining = endTime - currentUTCTime;
        setTimeLeft(remaining > 0 ? remaining : 0);
      } else {
        setTimeLeft(0); 
      }

      if(timeLeft === 0)
      {
        window.location.reload();
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [timeLeft])
  
  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000) % 60
    const minutes = Math.floor(ms / 1000 / 60) % 60
    const hours = Math.floor(ms / 1000 / 60 / 60)

    return `${hours}h ${minutes}m ${seconds}s`
  }
  return (
    <Stack sx={{ height: "100%" }} justifyContent="center" padding="16px">
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "0 auto"
        }}
      >
        <UnderConstructionIllustration width="100%" display="block" />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "900px",
          margin: "0 auto"
        }}
      >
        <Typography
          textAlign="center"
          fontWeight={500}
          fontSize={28}
          textTransform="uppercase"
          marginTop="80px"
          color="rgba(0, 0, 0, 0.87)"
        >
          We will be right back in: {formatTime(timeLeft)}
        </Typography>
        <Typography
          textAlign="center"
          fontSize={22}
          marginTop="20px"
          color="rgba(0, 0, 0, 0.87)"
        >
          SORRY! We are under maintenance
        </Typography>
        <Typography
          textAlign="center"
          fontSize={22}
          color="rgba(0, 0, 0, 0.87)"
        >
          Thank you for your patience
        </Typography>
      </Box>
    </Stack>
  )
}

export default MaintenanceErrorTimer
