import { atom } from 'jotai';

interface categoriesStateInterface {
    categoryData: any[];
}

export const INITIAL_categories_ATOM_VALUE: categoriesStateInterface = {
    categoryData: []
}

export const categoryAtom = atom(INITIAL_categories_ATOM_VALUE);

// export function categoriesAddCategory(setcategoriesState: any, layout: any) {
    // setcategoriesState((categories) => {
        // let c = {...categories}
// 
        // console.log(layout);
// 
        // c['layout'] = layout;
// 
        // return c;
    // });
// }

export async function getAllCategories(userID, groupID) {
    // const result = await axios.get(`${process.env.NEXT_PUBLIC_LINX_URL}/categories`, {
    //     params: {
    //         teamID: groupID,
    //         userID
    //     }
    // })
    // // console.log(result.data);
    // // console.log("!");
    // if (Array.isArray(result.data)) {
    //     return result.data;
    // }
    // return [result.data];

    // JUSTIN: this is for a reason, will be fixing shortly
    return [];
}

export async function loadLibraryExternally(userID, groupID, set) {
    if (userID === undefined) {
        userID = '712c5b9c-3996-4e2e-a30c-217cb660a189';
    }

    const categories = await getAllCategories(userID, groupID)

    set((oldState) => {
        return {
            categoryData: categories
        }
    });
}
